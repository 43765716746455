.FullscreenWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
}

.Error {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  text-align: center;

  h5 {
    margin: 1em 0 0;
  }

  p {
    color: rgb(18 33 91 / 24%);
  }

  button {
    margin-top: 2em;
  }
}

.ErrorMessage {
  margin-top: 2em;
  color: rgb(18 33 91 / 24%);
  font-weight: 400;
  font-size: 0.85rem;
}
